import { IconButton, Popover } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import styles from 'styles/contactDetails/contactDetails.module.scss';
import { useAppDispatch, useAppSelector, useDialog } from 'src/app/hooks';
import { setOpenFormNote } from 'slices/notesSlice';
import PhoneIcon from 'icons/phone-blue.svg';
import NoteIcon from 'icons/notes-blue.svg';
import MessageIcon from 'icons/message-blue.svg';
import WhatsUpIcon from 'icons/whats-app-blue.svg';
import DeleteIcon from 'icons/delete.svg';
import HideIcon from 'icons/hide.svg';
import Contact from 'types/contact';
import { deleteContact } from 'slices/contactsSlice';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import usePreparedDialogs from 'src/app/hooks/ui/usePreparedDialogs';
import DialogTypes from 'src/enums/DialogTypes';
import { deleteCallsBatch, selectCalls } from 'slices/callsSlice';
import AddFileButton from '../../features2/files/ui/Files/AddFileButton';
import clsx from 'clsx';
import Tooltip from 'components/UI/Tooltip';
import DirectedText from '../../features2/localization/ui/DirectedText';
import AddNoteMenuOption from 'src/features2/notes/ui/components/AddNoteMenuOption';
import AddTaskMenuOption from 'src/features2/tasks/ui/components/AddTaskOption';
import AddProposalMenuOption from 'src/features2/proposals/ui/components/AddProposalMenuOption';
import { useNotesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

interface ActionsProps {
  emails: string[];
  isCompanyReportOpened: boolean;
  isPersonReportOpened: boolean;

  currentContact: Contact;
  isWithoutPhoneNumber: boolean;
}

const Actions: React.FC<ActionsProps> = ({
  emails,
  isCompanyReportOpened,
  isPersonReportOpened,
  currentContact,
  isWithoutPhoneNumber,
}) => {
  const dispatch = useAppDispatch();
  const calls = useAppSelector(selectCalls);
  const { t } = useTranslation('contact-details');
  const { show: showDeleteDialog, dialogUI: deleteDialogUI } = useDialog();
  const { showDialog } = usePreparedDialogs();
  const { enqueueSnackbar } = useSnackbar();
  const isReport = isCompanyReportOpened || isPersonReportOpened;
  const [moreModalAnchorEl, setMoreModalAnchorEl] = useState(null);
  const [modalPhoneSelect, setModalPhoneSelect] = useState(null);
  const [modalWUSelect, setModalWUSelect] = useState(null);
  const [modalEmailSelect, setModalEmailSelect] = useState(null);
  const { checkLimits } = useNotesLimits();
  const correctEmailsArray = (
    emails?.length
      ? emails
      : currentContact?.emails
      ? currentContact.emails.map((emailObj) => emailObj.email)
      : []
  ).filter((v) => v);
  const correctPhoneNumbersArray =
    currentContact.phones && !isReport ? currentContact.phones : null;
  const onMoreClick: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setMoreModalAnchorEl(currentTarget);
  };

  const hideMoreModal = () => setMoreModalAnchorEl(null);

  const onMoreDontShowClick = () => {
    hideMoreModal();
    showDialog({
      type: DialogTypes.DONT_SHOW_IN_LEADER_DIALOG,
      dialogProps: {
        contactId: currentContact.uuid,
        callback: () => setMoreModalAnchorEl(null),
      },
    });
  };

  const onMoreDeleteClick = () => {
    hideMoreModal();
    showDeleteDialog();
  };

  const onDeleteDialogDeleteClick = async () => {
    withErrorSnackbar({
      onTry: async () => {
        const phonesCurrentContactArray = currentContact.phones.map(
          ({ phone }) => phone
        );
        const relatedCallsIds = calls
          .filter((call) => phonesCurrentContactArray.includes(call.phoneNumber))
          .map(({ id }) => id);
        await dispatch(deleteCallsBatch(relatedCallsIds));

        await dispatch(
          deleteContact({
            contactId: currentContact.uuid,
            workspaceId: currentContact.workspace_id,
          })
        );
      },
      enqueueSnackbar,
    });
  };
  return (
    <div className={styles.actionContainer}>
      {correctPhoneNumbersArray && !isWithoutPhoneNumber && (
        <>
          <Tooltip title={t(`actions.call`)} placement={'top'}>
            <div className={styles.actionButtonContainer}>
              {correctPhoneNumbersArray.length == 1 ? (
                <a
                  className={styles.actionLink}
                  href={`tel:${correctPhoneNumbersArray[0].phone}`}
                >
                  <IconButton className={styles.actionButton}>
                    <PhoneIcon />
                  </IconButton>
                </a>
              ) : (
                <>
                  <IconButton
                    className={styles.actionButton}
                    onClick={(e) => setModalPhoneSelect(e.target)}
                  >
                    <PhoneIcon />
                  </IconButton>
                </>
              )}
            </div>
          </Tooltip>
          <Popover
            anchorEl={modalPhoneSelect}
            classes={{ paper: styles.PhonesModalPaper }}
            anchorOrigin={{
              vertical: 55,
              horizontal: -55,
            }}
            onClose={() => setModalPhoneSelect(undefined)}
            open={!!modalPhoneSelect}
          >
            <div className={styles.PhoneSelectWrapper}>
              {correctPhoneNumbersArray?.map((phoneItem, idx) => (
                <a key={idx} href={`tel:${phoneItem.phone}`}>
                  {phoneItem.phone}
                </a>
              ))}
            </div>
          </Popover>
        </>
      )}
      {!isCompanyReportOpened && (
        <Tooltip title={t(`actions.note`)} placement={'top'}>
          <div className={styles.actionButtonContainer}>
            <IconButton
              className={styles.actionButton}
              onClick={() => checkLimits() && dispatch(setOpenFormNote(true))}
            >
              <NoteIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {!!correctEmailsArray?.length && (
        <>
          <Tooltip title={t(`duplicates:Email_field`)} placement={'top'}>
            <div className={styles.actionButtonContainer}>
              {correctEmailsArray.length == 1 ? (
                <a
                  className={styles.actionLink}
                  //@ts-ignore
                  href={`mailto:${correctEmailsArray[0]}`}
                >
                  <IconButton className={styles.actionButton}>
                    <MessageIcon />
                  </IconButton>
                </a>
              ) : (
                <>
                  <IconButton
                    className={styles.actionButton}
                    onClick={(e) => setModalEmailSelect(e.target)}
                  >
                    <MessageIcon />
                  </IconButton>
                </>
              )}
            </div>
          </Tooltip>
          <Popover
            anchorEl={modalEmailSelect}
            classes={{ paper: styles.PhonesModalPaper }}
            anchorOrigin={{
              vertical: 55,
              horizontal: -55,
            }}
            onClose={() => setModalEmailSelect(undefined)}
            open={!!modalEmailSelect}
          >
            <div className={styles.PhoneSelectWrapper}>
              {correctEmailsArray?.map((email, idx) => (
                <a key={idx} href={`mailto:${email}`}>
                  {email}
                </a>
              ))}
            </div>
          </Popover>
        </>
      )}
      {!isCompanyReportOpened &&
        !isWithoutPhoneNumber &&
        correctPhoneNumbersArray &&
        correctPhoneNumbersArray?.length && (
          <>
            <Tooltip title={t(`actions.send`)} placement={'top'}>
              <div className={styles.actionButtonContainer}>
                {/* <IconButton className={styles.actionButton}>
                <WhatsUpIcon />
              </IconButton> */}
                {correctPhoneNumbersArray?.length == 1 ? (
                  <a
                    href={`https://wa.me/${currentContact.phones[0].phone}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={styles.actionButton}>
                      <WhatsUpIcon />
                    </IconButton>
                  </a>
                ) : (
                  <>
                    <IconButton
                      className={styles.actionButton}
                      onClick={(e) => setModalWUSelect(e.target)}
                    >
                      <WhatsUpIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </Tooltip>
            <Popover
              anchorEl={modalWUSelect}
              classes={{ paper: styles.PhonesModalPaper }}
              anchorOrigin={{
                vertical: 55,
                horizontal: -55,
              }}
              onClose={() => setModalWUSelect(undefined)}
              open={!!modalWUSelect}
            >
              <div className={styles.PhoneSelectWrapper}>
                {correctPhoneNumbersArray?.map((phoneItem, idx) => (
                  <a key={idx} href={`https://wa.me/${phoneItem.phone}`}>
                    {phoneItem.phone}
                  </a>
                ))}
              </div>
            </Popover>
          </>
        )}
      {!isCompanyReportOpened && (
        <>
          <Tooltip title={t(`actions.more.title`)} placement={'top'}>
            <button
              className={clsx(
                styles.DefaultActionsButtonWrapper,
                correctPhoneNumbersArray && styles.Margin
              )}
              onClick={onMoreClick}
            >
              <div className={styles.DefaultActionsButton}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </Tooltip>
          <Popover
            anchorEl={moreModalAnchorEl}
            open={Boolean(moreModalAnchorEl)}
            onClose={hideMoreModal}
            classes={{ paper: styles.MoreModalPaper }}
            anchorOrigin={{
              vertical: 55,
              horizontal: -79,
            }}
          >
            <AddNoteMenuOption onClick={hideMoreModal} />
            <AddProposalMenuOption
              onClick={hideMoreModal}
              contactId={currentContact.uuid}
              type={'Contact details'}
            />
            <AddTaskMenuOption onClick={hideMoreModal} contactId={currentContact.uuid} />
            <AddFileButton onFilesAddedToQueue={hideMoreModal} />
            <button className={styles.MoreModalItem} onClick={onMoreDontShowClick}>
              <HideIcon />
              <span>
                <DirectedText style={{ marginLeft: 0 }}>
                  {t(`actions.more.dont_show_button_text`)}
                </DirectedText>
              </span>
            </button>
            <button className={styles.MoreModalItem} onClick={onMoreDeleteClick}>
              <DeleteIcon />
              <span>{t(`actions.more.delete_button_text`)}</span>
            </button>
          </Popover>
        </>
      )}
      {deleteDialogUI({
        titleText: t('dialogs:delete_dialog.title'),
        titleClassName: styles.DeleteDialogText,
        bodyText: t('dialogs:delete_dialog.description'),
        negativeButtonText: t('duplicates:Cancel_action'),
        positiveButtonText: t('duplicates:Delete_action'),
        positiveButtonClassName: styles.DeleteDialogText,
        onSuccess: onDeleteDialogDeleteClick,
      })}
    </div>
  );
};
export default Actions;
